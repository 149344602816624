:root {
  --accent: #FFFFFF;
  --primary: #3e1d14;
  --font: Gilroy;
}

body {
  color: var(--white);
  overflow: hidden;
}
